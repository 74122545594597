<template>
    <section class="user-table">
        <tool-bar has-search>
            Employees
            <template slot="buttons">
                <div class="level-item">
                    <button class="button">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add an Employee</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button">
                        <span class="icon is-small">
                            <font-awesome-icon icon="download"></font-awesome-icon>
                        </span>
                        <span>Download Report</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="table-wrapper">
            <b-table detailed
                     detail-key="_id"
                     v-bind:data="employees">
                <template>
                    <b-table-column v-slot="{ row }"
                                    field="id"
                                    label="Joined on"
                                    centered>
                        {{ row.createdAt | dateFnsFormatter("d/MMM/yyyy hh:mm a") }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    field="name"
                                    label="First Name"
                                    sortable>
                        {{ row.name }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    field="surname"
                                    label="Last Name"
                                    sortable>
                        {{ row.surname }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    field="phone"
                                    label="Phone"
                                    centered
                                    sortable>
                        {{ row.phone }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    field="title"
                                    label="Title"
                                    sortable>
                        {{ row.title }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    label="Type"
                                    centered
                                    sortable>
                        {{ row.type }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    label="Wage"
                                    centered >
                        {{ row.wage }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    label="Store"
                                    centered
                                    sortable>
                        {{ row.store }}
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    label="Status"
                                    centered>
                        <span class="tag is-success"
                              v-if="row.isEmployed">
                            Employed
                        </span>
                        <span class="tag is-warning"
                              v-if="!row.isEmployed">
                            Quited
                        </span>
                    </b-table-column>
                    <b-table-column v-slot="{ row }"
                                    label=""
                                    centered>
                        <router-link class="button is-small is-text"
                                     v-bind:to="`/employee/${ row._id }`">
                            <font-awesome-icon icon="edit"></font-awesome-icon>
                        </router-link>
                        <button class="button is-small is-text is-danger"
                                v-bind:class="{ 'is-loading': isDeleting }"
                                v-on:click="reauthenticateDelete(row)">
                            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                        </button>
                    </b-table-column>
                </template>

                <template v-slot:detail="{ row }">
                    <article class="media">
                        <div class="media-content">
                            <div class="content">
                                <nav class="level">
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <p class="heading">Prepayment</p>
                                            <p class="title">
                                                HK$0
                                            </p>
                                        </div>
                                    </div>
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <p class="heading">Spending</p>
                                            <p class="title">
                                                HK${{ calculateTotalOrderAmount(orders.filter(({ owner }) => owner === row._id)) + calculateTotalBookingAmount(bookings.filter(({ owner }) => owner === row._id)) }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <p class="heading">Orders</p>
                                            <p class="title">
                                                {{ orders.filter(({ owner }) => owner === row._id).length }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <p class="heading">Bookings</p>
                                            <p class="title">
                                                {{ bookings.filter(({ owner }) => owner === row._id).length }}
                                            </p>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </article>
                </template>
            </b-table>
        </div>

        <modal-confirmation has-confirm
                            isFunction
                            v-bind:confirmFunction="reauthenticate"
                            v-bind:callback="confirmDeleteUser"
                            v-model="isDeleteUserReAuthModalActive">
            <template slot="title">
                Reauthentication required
            </template>
            You are deleting a user named <strong>"{{ userToBeDeleted ? `${ userToBeDeleted.name } ${ userToBeDeleted.surname }` : "" }}"</strong>. <br>
            To continue, please enter <strong>your password</strong>.
        </modal-confirmation>

        <modal-confirmation has-confirm
                            v-bind:textual-confirm-answer="userToBeDeleted ? `${ userToBeDeleted.name } ${ userToBeDeleted.surname }` : ''"
                            v-bind:callback="deleteUser"
                            v-model="isDeleteUserModalActive">
            You are deleting a user named <strong>"{{ userToBeDeleted ? `${ userToBeDeleted.name } ${ userToBeDeleted.surname }` : "" }}"</strong>. <br>
            To confirm, please enter the <strong>name of the user</strong> below.
        </modal-confirmation>
    </section>
</template>

<script>
import login from "../../api/admin/login.js";
export default {
    name: "MemberTable",
    components: {
        ToolBar: () => import("../layouts/ToolBar.vue"),
        ModalConfirmation: () => import("../modals/ModalConfirmation.vue"),
    },
    data() {
        return {
            isDeleteUserReAuthModalActive: false,
            isDeleteUserModalActive: false,
            userToBeDeleted: null,

            isDeleting: false
        }
    },
    computed: {
        employees () {
            return [
                {
                    _id: "f6c68820-7cf2-4781-8540-d58bb38f92c3",
                    name: "Test",
                    surname: "Monthly",
                    phone: "12345678",
                    title: "Beautician",
                    type: "Full-time",
                    wage: "HK$17,500/Month",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 25),
                    isEmployed: true,
                },
                {
                    _id: "36c5e3f4-1716-449e-ac1f-95da039fbb97",
                    name: "Test",
                    surname: "Hourly",
                    phone: "12345678",
                    title: "Part-time Beautician",
                    type: "Part-time",
                    wage: "HK$75/Hour",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 32),
                    isEmployed: false,
                },
                {
                    _id: "36c5e3f4-1716-449e-ac1f-95da039fbb97",
                    name: "Test",
                    surname: "Monthly 2",
                    phone: "12345678",
                    title: "Beautician",
                    type: "Full-time",
                    wage: "HK$17,500/Month",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 32),
                    isEmployed: true,
                },
                {
                    _id: "36c5e3f4-1716-449e-ac1f-95da039fbb97",
                    name: "Test",
                    surname: "Test",
                    phone: "12345678",
                    title: "Beautician",
                    type: "Full-time",
                    wage: "HK$17,500/Month",
                    store: "Hang Lung Centre",
                    createdAt: new Date(2022, 9, 5, 11, 32),
                    isEmployed: true
                },
                {
                    _id: "36c5e3f4-1716-449e-ac1f-95da039fbb97",
                    name: "Test",
                    surname: "Hourly",
                    phone: "12345678",
                    title: "Promoter",
                    type: "Part-time",
                    wage: "HK$55/Hour",
                    store: "Mira Place",
                    createdAt: new Date(2022, 9, 5, 11, 32),
                    isEmployed: true,
                },
            ];
        },
    },
    methods: {
        calculateTotalOrderAmount(orders) {
            return orders.reduce(
                (acc, order) => acc += order.items.reduce(
                    (acc, item) => acc += item.prices[0].hkd * item.quantity,
                    0
                ),
                0
            )
        },

        calculateTotalBookingAmount(bookings) {
            return bookings.reduce(
                (acc, booking) => acc += booking.items.reduce(
                    (acc, item) => acc += item.skus.reduce(
                        (acc, sku) => acc += sku.hkd,
                        0
                    ),
                    0
                ),
                0
            )
        },

        async reauthenticate(password) {
            try {
                await login(
                    {
                        "email": this.user.email,
                        password
                    }
                )
            } catch (e) {
                console.error(e)
                return false
            }
            return true
        },

        reauthenticateDelete(user) {
            this.isDeleteUserReAuthModalActive = true
            this.userToBeDeleted = user
        },

        confirmDeleteUser() {
            this.isDeleteUserModalActive = true
        },

        async deleteUser() {
            this.isDeleting = true
            try {
                await this.$store.dispatch(
                    "users/deleteUser",
                    this.userToBeDeleted._id
                )
            } catch (e) {
                console.error(e)
                this.isDeleting = false
                return
            }
            this.isDeleting = false
        }
    }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
    padding: 1rem;
}

.media {
    align-items: center;
}
</style>
